<template>
    <v-footer fixed color="green">
        <v-container>
            <v-form @submit="addItem()" v-on:submit.prevent>
                <v-row>
                    <v-col class="col-xs-9 col-md-6 offset-md-3">
                        <v-card class="px-10">
                            <v-text-field v-model="newItemText" ref="newItemTextRef" label="New List Item" />
                        </v-card>
                    </v-col>
                    <v-col class="col-3 my-auto">
                        <v-btn type="submit" color="white" elevation="0"> ADD </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-footer>
</template>
<script>
export default {
    data() {
        return {
            newItemText: "",
        };
    },
    methods: {
        addItem() {
            if (this.newItemText) {
                this.addToList(this.newItemText);
                this.newItemText = "";
                // Select input field so user can quickly start typing next item.
                this.$refs.newItemTextRef.$refs.input.focus();
            }
        },
    },
    props: ["addToList"],
};
</script>